<template>
  <div class="next-withdrawals-container">
    <b-card class="next-withdrawals-card">
      <div class="row-style">
        <d-body-nav-bar
            :tabs="[]"
            position="float-right"
            title="views.payment.content.custom-infos.next-withdrawals.title"
        />
      </div>
      <div v-if="$store.getters['layout/getInnerWidth'] >= 772">
        <div class="row mt-2 no-gutters">
          <div
              :class="getCols('col-md-1')"
          >
            <img width="40" height="40" src="../../../../assets/Oval@2x.png">
          </div>
          <div
              class="next-withdrawals-name mt-2"
              :class="getCols('col-md-3')"
          >
            Marjolaine Chevalier
          </div>
          <div
              class="next-withdrawals-details mt-2"
              :class="getCols('col-md-3 text-center')"
          >
            Abonnement Illimité HC
          </div>
          <div
              class="next-withdrawals-details mt-2"
              :class="getCols('col-md-2 text-center')"
          >
            26/10/2020
          </div>
          <div
              class="next-withdrawals-name mt-2"
              :class="getCols('col-md-2 text-center')"
          >
            59,00 €
          </div>
        </div>
        <div class="row mt-3 no-gutters">
          <div
              :class="getCols('col-md-1')"
          >
            <img width="40" height="40" src="../../../../assets/Oval@2x.png">
          </div>
          <div
              class="next-withdrawals-name mt-2"
              :class="getCols('col-md-3')"
          >
            Marjolaine Chevalier
          </div>
          <div
              class="next-withdrawals-details mt-2"
              :class="getCols('col-md-3 text-center')"
          >
            Abonnement Illimité HC
          </div>
          <div
              class="next-withdrawals-details mt-2"
              :class="getCols('col-md-2 text-center')"
          >
            26/10/2020
          </div>
          <div
              class="next-withdrawals-name mt-2"
              :class="getCols('col-md-2 text-center')"
          >
            59,00 €
          </div>
        </div>
        <div class="row mt-3 no-gutters">
          <div
              :class="getCols('col-md-1')"
          >
            <img width="40" height="40" src="../../../../assets/Oval@2x.png">
          </div>
          <div
              class="next-withdrawals-name mt-2"
              :class="getCols('col-md-3')"
          >
            Marjolaine Chevalier
          </div>
          <div
              class="next-withdrawals-details mt-2"
              :class="getCols('col-md-3 text-center')"
          >
            Abonnement Illimité HC
          </div>
          <div
              class="next-withdrawals-details mt-2"
              :class="getCols('col-md-2 text-center')"
          >
            26/10/2020
          </div>
          <div
              class="next-withdrawals-name mt-2"
              :class="getCols('col-md-2 text-center')"
          >
            59,00 €
          </div>
        </div>
      </div>
      <div class="mt-3" v-else>
        <div class="row mt-3 no-gutters">
          <div
              :class="getCols('col-md-1')"
          >
            <img width="40" height="40" src="../../../../assets/Oval@2x.png">
          </div>
        </div>
        <b-row no-gutters align="center">
          <b-col
              cols="4"
              class="next-withdrawals-name pr-0 pl-0 text-center"
          >
            Marjolaine Chevalier
          </b-col>
          <b-col
              cols="4"
              class="next-withdrawals-details pr-0 pl-0 text-center"
          >
            Abonnement Illimité HC
          </b-col>
          <b-col
              cols="2"
              class="next-withdrawals-details pr-0 pl-0 text-right"
          >
            26/10/2020
          </b-col>
          <b-col
              cols="2"
              class="next-withdrawals-name pr-0 pl-0 text-right"
          >
            59,00 €
          </b-col>
        </b-row>
        <div class="row mt-3 no-gutters">
          <div
              :class="getCols('col-md-1')"
          >
            <img width="40" height="40" src="../../../../assets/Oval@2x.png">
          </div>
        </div>
        <b-row no-gutters align="center">
          <b-col
              cols="4"
              class="next-withdrawals-name pr-0 pl-0 text-center"
          >
            Marjolaine Chevalier
          </b-col>
          <b-col
              cols="4"
              class="next-withdrawals-details pr-0 pl-0 text-center"
          >
            Abonnement Illimité HC
          </b-col>
          <b-col
              cols="2"
              class="next-withdrawals-details pr-0 pl-0 text-right"
          >
            26/10/2020
          </b-col>
          <b-col
              cols="2"
              class="next-withdrawals-name pr-0 pl-0 text-right"
          >
            59,00 €
          </b-col>
        </b-row>
        <div class="row mt-3 no-gutters">
          <div
              :class="getCols('col-md-1')"
          >
            <img width="40" height="40" src="../../../../assets/Oval@2x.png">
          </div>
        </div>
        <b-row no-gutters align="center">
          <b-col
              cols="4"
              class="next-withdrawals-name pr-0 pl-0 text-center"
          >
            Marjolaine Chevalier
          </b-col>
          <b-col
              cols="4"
              class="next-withdrawals-details pr-0 pl-0 text-center"
          >
            Abonnement Illimité HC
          </b-col>
          <b-col
              cols="2"
              class="next-withdrawals-details pr-0 pl-0 text-right"
          >
            26/10/2020
          </b-col>
          <b-col
              cols="2"
              class="next-withdrawals-name pr-0 pl-0 text-right"
          >
            59,00 €
          </b-col>
        </b-row>
      </div>
      <b-row align="center" class="mt-4">
        <b-col class="show-all-label">
          Voir tous les prélèvements à venir
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
  export default{
    methods: {
      getCols (x) {
        const width = this.$store.getters["layout/getInnerWidth"];

        if (width < 772) {
          return 'col-md-12 text-center';
        } else {
          return x;
        }
      }
    }

  }
</script>
<style scoped>
.next-withdrawals-card .card-body {
  -ms-flex: 1 1 auto;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  min-height: 320px;
  padding: 1.25rem;
}

.next-withdrawals-container .card {
  box-shadow: 0px 10px 16px #E2E1E6;
  border-radius: 8px;
}
.next-withdrawals-name {
  text-align: left;
  font: normal normal bold 14px/22px Avenir;
  letter-spacing: 0.44px;
  color: #52575D;
  opacity: 1;
}
.next-withdrawals-details {
  text-align: left;
  font: normal normal normal 14px/22px Avenir;
  letter-spacing: 0.44px;
  color: #52575D;
  opacity: 1;
}

@media (min-width: 768px) {
  .col-md-1 {
    -ms-flex: 0 0 10.333333%;
    -webkit-box-flex: 0;
    flex: 0 0 10.333333%;
    max-width: 10.333333%;
  }
}

.show-all-label {
  font: normal normal 16px Avenir;
  color: #0B2772;
  opacity: 1;
}
</style>
